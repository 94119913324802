import React from "react";
import { NavigationBar } from "../../../components/NavigationBar";

const Header = () => (
  <header className="bg-voodoo-700 text-white p-4 md:p-8">
    <NavigationBar />
  </header>
);

export default Header;
