exports.components = {
  "component---node-modules-flogy-gatsby-theme-fgs-contact-src-pages-contact-js": () => import("./../../../../../node_modules/@flogy/gatsby-theme-fgs-contact/src/pages/contact.js" /* webpackChunkName: "component---node-modules-flogy-gatsby-theme-fgs-contact-src-pages-contact-js" */),
  "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-data-privacy-js": () => import("./../../../../../node_modules/@flogy/gatsby-theme-fgs-legal/src/pages/data-privacy.js" /* webpackChunkName: "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-data-privacy-js" */),
  "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-impressum-js": () => import("./../../../../../node_modules/@flogy/gatsby-theme-fgs-legal/src/pages/impressum.js" /* webpackChunkName: "component---node-modules-flogy-gatsby-theme-fgs-legal-src-pages-impressum-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-programm-index-tsx": () => import("./../../../src/pages/programm/index.tsx" /* webpackChunkName: "component---src-pages-programm-index-tsx" */),
  "component---src-pages-programm-sanity-event-categories-slug-current-index-tsx": () => import("./../../../src/pages/programm/{SanityEventCategories.slug__current}/index.tsx" /* webpackChunkName: "component---src-pages-programm-sanity-event-categories-slug-current-index-tsx" */),
  "component---src-pages-programm-sanity-events-event-category-slug-current-sanity-events-slug-current-tsx": () => import("./../../../src/pages/programm/{SanityEvents.eventCategory__slug__current}/{SanityEvents.slug__current}.tsx" /* webpackChunkName: "component---src-pages-programm-sanity-events-event-category-slug-current-sanity-events-slug-current-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */)
}

