import * as React from "react";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { StaticImage } from "gatsby-plugin-image";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

interface NavigationOption {
  text: string;
  url: string;
  partiallyActive: boolean;
}

const navigationOptions: NavigationOption[] = [
  {
    text: "Home",
    url: "/",
    partiallyActive: false,
  },
  {
    text: "Programm",
    url: "/programm",
    partiallyActive: true,
  },
  {
    text: "Über uns",
    url: "/ueber-uns",
    partiallyActive: true,
  },
  {
    text: "Kontakt",
    url: "/kontakt",
    partiallyActive: true,
  },
];

export const NavigationBar = () => {
  const [isOpen, setOpen] = React.useState<boolean>(false);

  const toggle = () => setOpen((open) => !open);

  return (
    <nav className="container mx-auto max-w-5xl">
      <div className="hidden md:flex justify-end items-center gap-10">
        <div className="hidden lg:block grow">
          <Link href="/">
            <StaticImage
              src="../images/logo-flach.png"
              alt="exSPIRIence Logo"
              placeholder="none"
              layout="constrained"
              width={260}
            />
          </Link>
        </div>
        {navigationOptions.map((option) => (
          <Link
            key={option.text}
            className="uppercase font-bold font-lexend tracking-widest text-white hover:text-salmon-400 hover:no-underline transition-colors"
            activeClassName="text-salmon-400"
            href={option.url}
            partiallyActive={option.partiallyActive}
          >
            {option.text}
          </Link>
        ))}
        <Link
          className="uppercase font-bold font-lexend tracking-widest text-white hover:text-salmon-400 hover:no-underline transition-colors"
          href="https://www.instagram.com/exspirience.windisch/"
        >
          <FontAwesomeIcon icon={faInstagram} title="Instagram" />
        </Link>
      </div>
      <div className="flex flex-col md:hidden">
        <div className="flex justify-between items-center">
          <Link className="flex mr-6" href="/">
            <StaticImage
              src="../images/logo-flach.png"
              alt="exSPIRIence Logo"
              placeholder="none"
              layout="constrained"
              width={260}
            />
          </Link>
          <button
            className="p-1 rounded-md text-salmon-400 hover:text-white hover:bg-white/20 transition-colors"
            aria-label="Hauptmenü"
            aria-expanded="false"
            onClick={toggle}
          >
            {isOpen ? (
              <svg
                className="h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                ></path>
              </svg>
            ) : (
              <svg
                className="h-8 w-8"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            )}
          </button>
        </div>
        {isOpen && (
          <div className="flex flex-col mt-3 gap-6">
            {navigationOptions.map((option) => (
              <Link
                key={option.text}
                href={option.url}
                className="uppercase font-bold font-lexend tracking-widest text-white hover:text-salmon-400 hover:no-underline transition-colors"
                activeClassName="text-salmon-400"
                partiallyActive={option.partiallyActive}
              >
                {option.text}
              </Link>
            ))}
            <Link
              className="uppercase font-bold font-lexend tracking-widest text-white hover:text-salmon-400 hover:no-underline transition-colors"
              href="https://www.instagram.com/exspirience.windisch/"
            >
              <FontAwesomeIcon icon={faInstagram} title="Instagram" />
            </Link>
          </div>
        )}
      </div>
    </nav>
  );
};
