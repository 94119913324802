import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "@flogy/gatsby-theme-fgs-layout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";

const Footer = () => (
  <footer className="bg-voodoo-700 text-voodoo-100 px-10 md:px-16 py-12 md:p-24">
    <div className="container mx-auto max-w-5xl">
      <div className="text-sm sm:text-base flex flex-col sm:flex-row items-start sm:items-center flex-wrap gap-4 sm:gap-8">
        <StaticImage
          className="shrink-0"
          src="../../../images/logo-flach.png"
          alt="exSPIRIence Logo"
          placeholder="none"
          width={260}
        />
        <Link href="https://www.instagram.com/exspirience.windisch/">
          <FontAwesomeIcon icon={faInstagram} title="Instagram" />
        </Link>
        <Link href="/impressum">Impressum</Link>
        <Link href="/datenschutz">Datenschutz</Link>
        <span className="block">Kapellenweg 8, 5210 Windisch</span>
      </div>
    </div>
  </footer>
);

export default Footer;
