const cookieConsentStyles = {
  contentStyle: {
    margin: 20,
  },
  buttonStyle: {
    padding: 10,
  },
}

export default cookieConsentStyles
