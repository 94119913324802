import * as React from "react";
import Header from "../@flogy/gatsby-theme-fgs-layout/components/header";
import Footer from "@flogy/gatsby-theme-fgs-layout/src/components/footer";
import { StaticImage } from "gatsby-plugin-image";

interface Props {
  children: React.ReactNode;
}

export const SiteLayout: React.FC<Props> = ({ children }) => (
  <div className="min-h-screen flex flex-col">
    <div className="fixed -z-10">
      <StaticImage
        className="h-screen"
        src="../images/people-next-to-campfire.webp"
        alt="Menschen sitzen um ein Lagerfeuer"
        placeholder="blurred"
        objectFit="cover"
        layout="constrained"
      />
    </div>
    <Header />
    <main className="grow flex flex-col">{children}</main>
    <Footer />
  </div>
);
