import React from "react";
import { SiteLayout } from "../../../components/SiteLayout";

const Layout = ({ children }) => (
  <SiteLayout>
    <section className="bg-white text-slate-700 px-10 md:px-16 py-12 md:p-24 flex-1">
      <div className="container mx-auto max-w-5xl">{children}</div>
    </section>
  </SiteLayout>
);

export default Layout;
