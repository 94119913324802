module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"exSPIRIence","short_name":"exSPIRIence","start_url":"/","background_color":"#493751","theme_color":"#493751","display":"minimal-ui","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"89233304eeed9675f2ca3acec0d44472"},
    },{
      plugin: require('../../../node_modules/@flogy/gatsby-theme-fgs-legal/gatsby-browser.js'),
      options: {"plugins":[],"currentWebsiteUrl":"https://exspirience.ch","companyNominative":"Die evangelisch-methodistische Kirche Windisch-Brugg","companyDative":"der evangelisch-methodistischen Kirche Windisch-Brugg","companyAccusative":"die evangelisch-methodistische Kirche Windisch-Brugg","companyLocation":"Windisch","impressum":{"path":"/impressum","addresses":[{"title":"Verantwortlich für den Inhalt","company":"Evangelisch-methodistische Kirche Windisch-Brugg","street":"Kapellenweg 8","location":"5210 Windisch","email":"windisch@emk-schweiz.ch"},{"title":"Umsetzung der Website","company":"Florian Gyger Software","street":"Gustav Zeiler-Ring 3","location":"5600 Lenzburg","website":"https://floriangyger.ch"}]},"dataPrivacy":{"path":"/datenschutz","usedFeatures":["serverLogFiles","contactForm","ssl"],"lastChangedDate":"22.10.2022","responsiblePerson":{"nameAccusative":"Herrn Florian Gyger","email":"florian.gyger@floriangyger.ch"}},"cookieBanner":{"enable":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
